import React, { useState, useEffect } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Select,
  Divider,
  Spinner,
  Button,
  Icon,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
  Flex,
  Switch,
  InputGroup,
  InputRightAddon,
  Image,
  Badge,
  Textarea,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import actions from './actions';
import { convertTZ, showErrorToast, showSuccessToast } from './utils';
import { useForm } from 'react-hook-form';

const Upcoming = () => {
  const [items, setItems] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [loading, setLoading] = useState(false);
  const { handleSubmit, errors, register, formState } = useForm();
  const [createLessonModal, setCreateLessonModal] = useState(false);
  const [createQuizModal, setCreateQuizModal] = useState(false);

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      await fetchItems();
    };

    fetchData();
  }, []);

  const fetchItems = async () => {
    setLoading(true);
    try {
      const { items: i = [], teachers: t = [] } = await actions.fetchUnits();
      setItems(i);
      setTeachers(t);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const onCreateLesson = async (data, type) => {
    setLoading(true);
    try {
      data.type = type;
      await actions.createClass(data);
      await fetchItems();
      setCreateLessonModal(false);
      setCreateQuizModal(false);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const validateId = value => {
    let error;
    if (!value) {
      error = `Required`;
    }
    return error || true;
  };

  const renderCreateLessonModal = () => (
    <Modal
      size="lg"
      isOpen={createLessonModal}
      onClose={() => setCreateLessonModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Lesson</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateLesson(data))}>
            <FormControl mt={4} isInvalid={errors.title}>
              <Text fontWeight="bold">Title</Text>
              <Input
                name="title"
                placeholder="Lesson Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.description}>
              <Text fontWeight="bold">Description</Text>
              <Input
                name="description"
                placeholder="Description"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.poster_url}>
              <Text fontWeight="bold">Poster URL</Text>
              <Input
                name="poster_url"
                placeholder="Poster URL"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>{errors.poster_url && errors.poster_url.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.learning_goal}>
              <Text fontWeight="bold">Learning Goal</Text>
              <Input
                name="learning_goal"
                placeholder="Learning Goal"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>
                {errors.learning_goal && errors.learning_goal.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.age_range}>
              <Text fontWeight="bold">Grade Level</Text>
              <Input
                name="age_range"
                placeholder="Grade Level"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>{errors.age_range && errors.age_range.message}</FormErrorMessage>
            </FormControl>
            <Stack isInline>
              <FormControl mt={4} isInvalid={errors.subject}>
                <Text fontWeight="bold">Subject</Text>
                <Input
                  name="subject"
                  placeholder="Subject"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register()}
                />
                <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.subject_icon}>
                <Text fontWeight="bold">Subject Icon</Text>
                <Input
                  name="subject_icon"
                  placeholder="Subject Icon"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register()}
                />
                <FormErrorMessage>
                  {errors.subject_icon && errors.subject_icon.message}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack isInline>
              <FormControl mt={4} isInvalid={errors.order}>
                <Text fontWeight="bold">Track Order</Text>
                <Input
                  name="order"
                  placeholder="Track Order"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  type="number"
                  ref={register()}
                />
                <FormErrorMessage>{errors.order && errors.order.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.duration}>
                <Text fontWeight="bold">Duration (Min)</Text>
                <Input
                  name="duration"
                  placeholder="Duration (Min)"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  type="number"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.duration && errors.duration.message}</FormErrorMessage>
              </FormControl>
            </Stack>
            <FormControl mt={4} isInvalid={errors['cohort_id']}>
              <Text fontWeight="bold">Teacher</Text>
              <Select
                name="teacher_id"
                ref={register({
                  validate: validateId,
                })}
              >
                <option disabled selected value>
                  -- select a teacher --
                </option>
                {teachers &&
                  teachers.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.title}
                    </option>
                  ))}
              </Select>

              <FormErrorMessage>{errors.teacher_id && errors.teacher_id.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setCreateLessonModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateLesson(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderCreateQuizModal = () => (
    <Modal size="lg" isOpen onClose={() => setCreateQuizModal(false)} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Quiz</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateLesson(data, 'quiz'))}>
            <FormControl mt={4} isInvalid={errors.title}>
              <Text fontWeight="bold">Title</Text>
              <Input
                name="title"
                placeholder="Lesson Title"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>

            <FormControl mt={4} isInvalid={errors.learning_goal}>
              <Text fontWeight="bold">Learning Goal</Text>
              <Input
                name="learning_goal"
                placeholder="Learning Goal"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register()}
              />
              <FormErrorMessage>
                {errors.learning_goal && errors.learning_goal.message}
              </FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button
            variant="ghost"
            fontWeight="medium"
            mr={3}
            onClick={() => setCreateQuizModal(false)}
          >
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateLesson(data, 'quiz'))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderItem = (item = {}) => {
    const {
      title,
      id,
      slot_id,
      class_id,
      is_public,
      resource_id,
      timestamp,
      bookings = [],
      classes = 0,
    } = item;

    return (
      <Box key={slot_id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={2}>
          {is_public && (
            <Text fontSize="sm" rounded="lg" bg="purple.100" p={2}>
              Drop In Class
            </Text>
          )}
          <Box fontWeight="bold">
            <Link to={`/classes/${id}`}>{title} </Link>
          </Box>
          <Box fontSize="xs" color="gray.600">
            <Text>Lesson ID: {id}</Text>
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <Box maxW="md" mx="auto" mt={5} p={5}>
      {createLessonModal && renderCreateLessonModal()}
      {createQuizModal && renderCreateQuizModal()}
      <Text fontWeight="bold" fontSize="2xl">
        Lessons
      </Text>
      <Divider />

      {!loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Text color="gray.500">No units</Text>
        </Box>
      )}
      <Stack mt={2} spacing={2}>
        <Button
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => setCreateLessonModal(true)}
        >
          <Icon mr={2} name="add" />
          Create Lesson
        </Button>
        <Button
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => {
            setCreateQuizModal(true);
          }}
        >
          <Icon mr={2} name="add" />
          Create Quiz
        </Button>
        {items.map(renderItem)}
      </Stack>
      {loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Divider />
    </Box>
  );
};

export default Upcoming;
