import React, { useState, useEffect } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Select,
  Divider,
  Spinner,
  Button,
  Icon,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
  Flex,
} from '@chakra-ui/core';
import { Link, useParams } from 'react-router-dom';
import actions from './actions';
import { convertTZ, showErrorToast, showSuccessToast } from './utils';
import { useForm } from 'react-hook-form';

const Upcoming = () => {
  const [unit, setUnit] = useState({});
  const [loading, setLoading] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [slotsModal, setSlotsModal] = useState(false);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const { handleSubmit, errors, register, formState } = useForm();
  const { unit_id } = useParams();

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      await fetchItem();
    };

    fetchData();
  }, []);

  const fetchItem = async () => {
    setLoading(true);
    try {
      const { result: _ = {} } = await actions.fetchUnit(unit_id);
      console.log(_);
      setUnit(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const validateUrl = value => {
    let error;
    if (!value) {
      error = `URL required`;
    } else if (!value.startsWith('https://')) error = 'Must be valid URL';
    return error || true;
  };

  const validateId = value => {
    let error;
    if (!value) {
      error = `ID required`;
    }
    return error || true;
  };

  const onCreateBooking = async (data = {}) => {
    try {
      const res = await actions.createBooking(unit_id, data);
      console.log(res);
      await fetchItem();
      showSuccessToast(toast, 'Booking created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setBookingModal(false);
  };

  const onUpdateBooking = async (e, parent_id, booking_id) => {
    /*try {
      const res = await actions.createBooking(unit_id, data);
      console.log(res);
      await fetchItem();
      showSuccessToast(toast, 'Booking updated ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setBookingModal(false);*/
  };

  const onCreateSlots = async (data = {}) => {
    try {
      const res = await actions.createSlots(unit_id, data);
      await fetchItem();
      setSlotsModal(false);

      showSuccessToast(toast, 'Slots created ✅');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const renderItem = (item = {}) => {
    const { title, date, items = [], id } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Link to={`/units/${unit_id}/classes/${id}`}>
          <Stack spacing={1} mr={2}>
            <Box fontWeight="bold">{title}</Box>
            <Box fontSize="sm">ID: {id}</Box>
            <Stack spacing={2}>
              {items.map(i => {
                return (
                  <a style={{ textDecoration: 'underline' }} key={i.id} href={i.url}>
                    {i.title}
                  </a>
                );
              })}
            </Stack>
            <Box fontSize="xs" color="gray.500">
              {date}
            </Box>
          </Stack>
        </Link>
      </Box>
    );
  };

  const renderSlotCohort = (item = {}) => {
    const { title, date, items = [], id } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={1} mr={2}>
          <Box fontWeight="bold">{title}</Box>
          <Box fontSize="sm">ID: {id}</Box>
          <Stack spacing={2}>
            {items.map(i => {
              return (
                <a style={{ textDecoration: 'underline' }} key={i.id} href={i.url}>
                  {i.title}
                </a>
              );
            })}
          </Stack>
          <Box fontSize="xs" color="gray.500">
            {date}
          </Box>
        </Stack>
      </Box>
    );
  };

  const renderBookingModal = () => (
    <Modal
      size="xl"
      isOpen={bookingModal}
      onClose={() => setBookingModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Booking</ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(async data => await onCreateBooking(data))}>
            <FormControl mt={4} isInvalid={errors.parent_id}>
              <Text fontWeight="bold">Parent ID or Email</Text>
              <Input
                name="parent_id"
                placeholder="Parent ID or Email"
                autoFocus
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register({
                  validate: validateId,
                })}
              />
              <FormErrorMessage>{errors.parent_id && errors.parent_id.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors.student_id}>
              <Text fontWeight="bold">Child ID (Optional)</Text>
              <Input
                name="student_id"
                placeholder="Child ID"
                borderRadius="4px"
                height="50px"
                textAlign="center"
                fontSize="lg"
                ref={register}
              />
              <FormErrorMessage>{errors.student_id && errors.student_id.message}</FormErrorMessage>
            </FormControl>
            <FormControl mt={4} isInvalid={errors['cohort_id']}>
              <Text fontWeight="bold">Select Cohort</Text>
              <Select
                name="cohort_id"
                ref={register({
                  validate: validateId,
                })}
              >
                <option disabled selected value>
                  {' '}
                  -- select a cohort --{' '}
                </option>
                {unit.cohortOptions &&
                  unit.cohortOptions.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.title}
                    </option>
                  ))}
              </Select>

              <FormErrorMessage>{errors.cohort_id && errors.cohort_id.message}</FormErrorMessage>
            </FormControl>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" fontWeight="medium" mr={3} onClick={() => setBookingModal(false)}>
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateBooking(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderSlotsModal = () => (
    <Modal
      size="xl"
      isOpen={slotsModal}
      onClose={() => setSlotsModal(false)}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Teacher Slots</ModalHeader>
        <ModalBody>
          <Text>⚠️ All time slots are in US Eastern</Text>
          <form onSubmit={handleSubmit(async data => await onCreateBooking(data))}>
            <FormControl mt={4} isInvalid={errors['cohort_id']}>
              <Text fontWeight="bold">Select Teacher</Text>
              <Select
                name="teacher_id"
                ref={register({
                  validate: validateId,
                })}
              >
                {unit.teacherOptions &&
                  unit.teacherOptions.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.title}
                    </option>
                  ))}
              </Select>

              <FormErrorMessage>{errors.cohort_id && errors.cohort_id.message}</FormErrorMessage>
            </FormControl>
            <Stack mt={2} spacing={4}>
              {unit.classes &&
                unit.classes.map(c => (
                  <Box>
                    <Text fontWeight="bold">{`${c.title}`}</Text>
                    <Flex>
                      <FormControl isInvalid={errors.student_id}>
                        <Text>Date</Text>
                        <Select
                          name={`${c.id}#date`}
                          ref={register({
                            validate: validateId,
                          })}
                        >
                          <option disabled selected value={false}>
                            {' '}
                            -- select a date --{' '}
                          </option>

                          {unit.dateOptions &&
                            unit.dateOptions.map(c => (
                              <option key={c.id} value={c.id}>
                                {c.title}
                              </option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors[c.id] && errors[c.id].message}</FormErrorMessage>
                      </FormControl>
                      <FormControl ml={2} isInvalid={errors.student_id}>
                        <Text>Time (Eastern)</Text>
                        <Select
                          name={`${c.id}#time`}
                          ref={register({
                            validate: validateId,
                          })}
                        >
                          <option disabled selected value={false}>
                            {' '}
                            -- select a time --{' '}
                          </option>

                          {unit.timeOptions &&
                            unit.timeOptions.map(c => (
                              <option key={c.id} value={c.id}>
                                {c.title}
                              </option>
                            ))}
                        </Select>
                        <FormErrorMessage>{errors[c.id] && errors[c.id].message}</FormErrorMessage>
                      </FormControl>
                    </Flex>
                    <Divider />
                  </Box>
                ))}
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" fontWeight="medium" mr={3} onClick={() => setSlotsModal(false)}>
            Cancel
          </Button>
          <Button
            isLoading={formState.isSubmitting}
            bg="purple.500"
            fontWeight="medium"
            color="white"
            onClick={handleSubmit(async data => await onCreateSlots(data))}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  const renderBooking = (booking = {}) => {
    const {
      parent,
      child,
      email,
      phone,
      child_id,
      parent_id,
      cohort_id,
      intercom_url,
      booking_id,
      status,
      stars = 0,
      browser,
      device,
      age,
    } = booking;

    return (
      <Stack
        key={booking_id}
        spacing={0}
        textAlign="left"
        fontSize="xs"
        p={2}
        border="1px"
        borderRadius="4px"
        borderColor="gray.300"
      >
        <Text>
          <strong>Parent:</strong> {parent} | <strong>Email:</strong> {email}
        </Text>
        <Text>
          <strong>Phone:</strong> {phone} |{' '}
          {intercom_url && (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
              href={intercom_url}
            >
              Intercom
            </a>
          )}
        </Text>
        <Text>
          <strong>Child:</strong> {child} | <strong>Age:</strong> {age}
          {stars}
          {status && (
            <>
              <Text as="span">{` | `}</Text>
              <Text
                as="span"
                fontSize="xs"
                fontWeight="bold"
                px={2}
                borderRadius="3px"
                bg={status === 'Joined' ? 'green.500' : 'red.500'}
                color="white"
              >
                {status}
              </Text>
            </>
          )}
        </Text>
        {(browser || device) && (
          <Text>
            <strong>Browser:</strong> {browser} | <strong>Device:</strong> {device}
          </Text>
        )}
        <Text>
          <strong>Child ID:</strong> {child_id}
        </Text>
        <Text>
          <strong>Parent ID:</strong> {parent_id}
        </Text>
        <Text>
          <strong>Booking ID:</strong> {booking_id}
        </Text>

        <Select
          mt={2}
          isDisabled={loading}
          value={cohort_id}
          onChange={e => onUpdateBooking(e, parent_id, booking_id)}
        >
          {unit.cohortOptions &&
            unit.cohortOptions.map(c => (
              <option key={c.id} value={c.id}>
                {c.title}
              </option>
            ))}
        </Select>
      </Stack>
    );
  };

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      {renderBookingModal()}
      {renderSlotsModal()}
      <Box>
        <Text fontWeight="black" fontSize="2xl">
          {unit.title}
        </Text>
        {unit.is_public && (
          <Text p={2} fontSize="sm" rounded="lg" bg="purple.100">
            Drop In Class
          </Text>
        )}
      </Box>

      <Divider />
      {loading && !unit.classes && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      {!loading && (
        <Stack mt={2} spacing={2}>
          {unit.classes && unit.classes.map(renderItem)}
        </Stack>
      )}
      <Divider />
      <Text mt={5} fontWeight="bold" fontSize="xl">
        Bookings
      </Text>
      <Divider />
      <Stack>
        {unit.bookings && unit.bookings.map(renderBooking)}
        {unit.bookings && unit.bookings.length === 0 && (
          <Text fontSize="sm" color="gray.600">
            No bookings
          </Text>
        )}
        {unit && !unit.is_public && (
          <Button
            isLoading={loading}
            fontWeight="bold"
            bg="gray.500"
            color="white"
            h="50px"
            fontSize="lg"
            style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            onClick={() => setBookingModal(true)}
          >
            <Icon mr={2} name="add" />
            Create Booking
          </Button>
        )}
      </Stack>
      <Divider />
      <Text mt={5} fontWeight="bold" fontSize="xl">
        Cohorts
      </Text>
      <Divider />
      <Stack>
        {unit.cohortOptions && unit.cohortOptions.map(renderSlotCohort)}
        <Button
          isLoading={loading}
          fontWeight="bold"
          bg="gray.500"
          color="white"
          h="50px"
          fontSize="lg"
          style={{ textShadow: '0 -1px 0 rgba(0, 0, 0, .35)' }}
          onClick={() => setSlotsModal(true)}
        >
          <Icon mr={2} name="add" />
          Create Cohort
        </Button>
      </Stack>
      <Divider />
    </Box>
  );
};

export default Upcoming;
