import React, { useState, useEffect } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Flex,
  Divider,
  Spinner,
  Button,
  Icon,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import actions from './actions';
import { convertTZ, showErrorToast } from './utils';
import { useForm } from 'react-hook-form';

const Upcoming = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { handleSubmit, errors, register, formState } = useForm();

  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      await fetchResources();
    };

    fetchData();
  }, []);

  const fetchResources = async () => {
    setLoading(true);
    try {
      const { items: _ = [] } = await actions.fetchResources();
      setItems(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const validateUrl = value => {
    let error;
    if (!value) {
      error = `URL required`;
    } else if (!value.startsWith('https://')) error = 'Must be valid URL';
    return error || true;
  };

  const validateId = value => {
    let error;
    if (!value) {
      error = `ID required`;
    }
    return error || true;
  };

  const onCreateResource = async (data = {}) => {
    const { class_id, unit_id, ...rest } = data;
    try {
      const res = await actions.createResource(unit_id, class_id, rest);
      await fetchResources();
      onClose();
      console.log(res);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
  };

  const renderItem = (item = {}) => {
    const { title, date, items = [], id } = item;

    return (
      <Box key={id} border="1px" borderRadius="4px" p={3} borderColor="gray.200">
        <Stack spacing={1} mr={2}>
          <Box fontWeight="bold">{title}</Box>
          <Box fontSize="sm">ID: {id}</Box>
          <Stack spacing={2}>
            {items.map(i => {
              return (
                <a style={{ textDecoration: 'underline' }} key={i.id} href={i.url}>
                  {i.title}
                </a>
              );
            })}
          </Stack>
          <Box fontSize="xs" color="gray.500">
            {date}
          </Box>
        </Stack>
      </Box>
    );
  };

  return (
    <Box maxW="md" mx="auto" mt={5} p={5}>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Resource</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(async data => await onCreateResource(data))}>
              <FormControl mt={4} isInvalid={errors.unit_id}>
                <Text fontWeight="bold">Unit ID</Text>
                <Input
                  name="unit_id"
                  placeholder="Unit ID"
                  autoFocus
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.unit_id && errors.unit_id.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.class_id}>
                <Text fontWeight="bold">Class ID</Text>
                <Input
                  name="class_id"
                  placeholder="Class ID"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateId,
                  })}
                />
                <FormErrorMessage>{errors.class_id && errors.class_id.message}</FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.slides_presentation}>
                <Text fontWeight="bold">Presentation URL</Text>
                <Input
                  name="slides_presentation"
                  placeholder="Control URL"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateUrl,
                  })}
                />
                <FormErrorMessage>
                  {errors.slides_presentation && errors.slides_presentation.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.slides_control}>
                <Text fontWeight="bold">Control URL</Text>
                <Input
                  name="slides_control"
                  placeholder="Control URL"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateUrl,
                  })}
                />
                <FormErrorMessage>
                  {errors.slides_control && errors.slides_control.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl mt={4} isInvalid={errors.feedback}>
                <Text fontWeight="bold">Feedback URL</Text>
                <Input
                  name="feedback"
                  placeholder="Feedback URL"
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({
                    validate: validateUrl,
                  })}
                />
                <FormErrorMessage>{errors.feedback && errors.feedback.message}</FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" fontWeight="medium" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              isLoading={formState.isSubmitting}
              bg="purple.500"
              fontWeight="medium"
              color="white"
              onClick={handleSubmit(async data => await onCreateResource(data))}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Text fontWeight="bold" fontSize="2xl">
        Resources
      </Text>
      <Divider />
      {loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      {!loading && (
        <Stack mt={2} spacing={2}>
          <Button
            isLoading={loading}
            fontWeight="bold"
            bg="gray.500"
            color="white"
            h="50px"
            fontSize="lg"
            style={{ text: '0 -1px 0 rgba(0, 0, 0, .35)' }}
            onClick={onOpen}
          >
            <Icon mr={2} name="add" />
            Create
          </Button>
          {!loading && items.length === 0 && <Text color="gray.500">No resources</Text>}
          {items.map(renderItem)}
        </Stack>
      )}
      <Divider />
    </Box>
  );
};

export default Upcoming;
