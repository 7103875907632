import React, { useState, useEffect } from 'react';
import './App.css';
import { Text, Box, Stack } from '@chakra-ui/core';
import { Switch, Route, Redirect, BrowserRouter as Router } from 'react-router-dom';
import { detect } from 'detect-browser';
import Header from './Header';
import Cohorts from './Cohorts';
import Units from './Units';
import Resources from './Resources';
import Unit from './Unit';
import ZipClass from './ZipClass';
import Room from './Room';
import Recommend from './Recommend';

import awsconfig from './aws-exports.js';
import Amplify from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

Amplify.configure(awsconfig);

const App = () => {
  const browser = detect();
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/courses" render={props => <Cohorts {...props} />} />
          <Route exact path="/resources" render={props => <Resources {...props} />} />
          <Route exact path="/classes" render={props => <Units {...props} />} />
          <Route exact path="/units/:unit_id" render={props => <Unit {...props} />} />
          <Route exact path="/utils" render={props => <Recommend {...props} />} />

          <Route
            exact
            path="/rooms/:class_id/slots/:slot_id"
            render={props => <Room {...props} />}
          />

          <Route
            path="/units/:unit_id/classes/:class_id"
            render={props => <ZipClass {...props} />}
          />
          <Route path="/classes/:class_id" render={props => <ZipClass {...props} />} />
          <Redirect exact to="/classes" />
        </Switch>
      </Router>

      <Box d="flex" justifyContent="center" p={[3, null, 5]} bg="white">
        <Stack alignItems="center">
          <Stack spacing={5} isInline>
            <Text fontWeight="medium" fontSize="sm">
              <a
                href="https://zipschool.webflow.io/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms Of Service
              </a>
            </Text>
            <Text fontWeight="medium" fontSize="sm">
              <a
                href="https://zipschool.webflow.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </Text>
          </Stack>
          <Stack isInline alignItems="center">
            <Text color="gray.500" fontWeight="normal" fontSize="sm">
              © 2021{' '}
              <a href="https://zipschool.com?ref=app" rel="noopener noreferrer" target="_blank">
                Zip Education Inc.
              </a>
            </Text>
            <Text color="gray.400" fontSize="xs">
              v1.2.9
            </Text>
            {browser && (
              <Text color="gray.400" fontSize="xs">
                {browser.os} | {browser.name}
              </Text>
            )}
          </Stack>
        </Stack>
      </Box>
    </div>
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in">
        <div slot="secondary-footer-content"></div>
      </AmplifySignIn>
    </AmplifyAuthenticator>
  );
};

export default App;
