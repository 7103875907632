import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, CSSReset, theme } from '@chakra-ui/core';

const customTheme = {
  ...theme,
  fonts: {
    body: 'Circular, sans-serif',
    heading: theme.fonts.heading,
    mono: theme.fonts.mono,
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
    black: 800,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
