import { API } from 'aws-amplify';

const fetchCohorts = async () => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  };

  try {
    const { result = {} } = await API.get('ZipAdminAPI', '/cohorts', params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchUnits = async () => {
  const params = {
    queryStringParameters: fetchQueryParams(),
  };

  try {
    const { result = {} } = await API.get('ZipAdminAPI', '/units', params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateClassDuration = async class_id => {
  const params = {
    body: { duration: true },
  };

  try {
    const { result = {} } = await API.put('ZipAdminAPI', `/classes/${class_id}`, params);
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createClass = async request => {
  const params = {
    body: request,
  };

  try {
    const res = await API.post('ZipAdminAPI', '/units', params);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchUnit = async unit_id => {
  try {
    const res = await API.get('ZipAdminAPI', `/units/${unit_id}`);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchClass = async (unit_id, class_id) => {
  try {
    const res = await API.get('ZipAdminAPI', `/units/${unit_id}/classes/${class_id}`);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchSlotEditOptions = async (unit_id, class_id, slot_id) => {
  try {
    const res = await API.get(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/slots/${slot_id}/edit`
    );
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchResources = async () => {
  try {
    const res = await API.get('ZipAdminAPI', '/resources');
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchRecomendations = async (user_id, campaign_id) => {
  const params = {
    body: { user_id, campaign_id },
  };

  try {
    const res = await API.post('ZipAdminAPI', '/recommendations', params);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchClassroom = async (class_id, slot_id, mode, code) => {
  const params = {
    queryStringParameters: { mode, code },
  };

  try {
    const { result = {} } = await API.get(
      'ZipAdminAPI',
      `/rooms/${class_id}/slots/${slot_id}`,
      params
    );
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchSignedUrl = async (class_id, type, file, start, end, callback) => {
  const url =
    start && end
      ? `/upload?class_id=${class_id}&type=${type}&start=${start}&end=${end}`
      : `/upload?class_id=${class_id}&type=${type}`;
  try {
    API.get('ZipUploadApi', url).then(r => {
      callback(r);
    });
    //return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createPresentation = async (unit_id, class_id, presentation = {}) => {
  const params = {
    body: { presentation },
  };

  try {
    const { items } = await API.post(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/resources`,
      params
    );
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createSlots = async (unit_id, data = {}) => {
  const params = {
    body: { data },
  };

  try {
    const { items } = await API.post('ZipAdminAPI', `/units/${unit_id}/slots`, params);
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createQuestion = async (unit_id, class_id, question = {}) => {
  const params = {
    body: { question },
  };

  try {
    const { items } = await API.post(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/resources`,
      params
    );
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createReward = async (unit_id, class_id, reward = {}) => {
  const params = {
    body: { reward },
  };

  try {
    const { items } = await API.post(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/resources`,
      params
    );
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const deleteResource = async (unit_id, class_id, resource_id) => {
  const params = {
    body: { action: 'delete' },
  };

  try {
    const { result = {} } = await API.put(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/resources/${resource_id}`,
      params
    );
    return result;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createGame = async (unit_id, class_id, game = {}) => {
  const params = {
    body: { game },
  };

  try {
    const { items } = await API.post(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/resources`,
      params
    );
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createBooking = async (unit_id, booking = {}) => {
  const params = {
    body: { booking },
  };

  try {
    const res = await API.post('ZipAdminAPI', `/units/${unit_id}/booking`, params);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createTimeSlot = async (unit_id, class_id, slot = {}) => {
  const params = {
    body: { slot },
  };

  try {
    const { items } = await API.post(
      'ZipAdminAPI',
      `/units/${unit_id}/classes/${class_id}/slots`,
      params
    );
    return { items };
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateSlot = async (class_id, slot_id, updates = {}) => {
  const params = {
    body: { updates },
  };

  try {
    const res = await API.put('ZipAdminAPI', `/classes/${class_id}/slots/${slot_id}`, params);
    console.log(res);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const createLink = async url => {
  const params = {
    body: { url },
  };

  try {
    const res = await API.post('ZipAdminAPI', `/links`, params);
    console.log(res);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchLink = async link_id => {
  try {
    const res = await API.get('ZipAdminAPI', `/links/${encodeURIComponent(link_id)}`);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateBooking = async (parent_id, booking_id, action, data = {}) => {
  const params = {
    body: { action, data },
  };

  try {
    const res = await API.put(
      'ZipAdminAPI',
      `/parents/${parent_id}/bookings/${booking_id}`,
      params
    );
    console.log(res);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const updateClass = async (class_id, updates = {}) => {
  const params = {
    body: { ...updates },
  };

  try {
    const res = await API.put('ZipAdminAPI', `/classes/${class_id}`, params);
    console.log(res);
    return res;
  } catch (err) {
    const errData = err.response ? err.response.data : err;
    throw errData;
  }
};

const fetchQueryParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  var retval;
  // Display the key/value pairs
  for (const pair of searchParams.entries()) {
    if (!retval) retval = { [pair[0]]: pair[1] };
    else retval[pair[0]] = pair[1];
  }

  return retval;
};

export default {
  fetchCohorts,
  fetchResources,
  updateSlot,
  fetchUnits,
  fetchUnit,
  createClass,
  fetchClass,
  createTimeSlot,
  createQuestion,
  createPresentation,
  createBooking,
  fetchSlotEditOptions,
  createGame,
  fetchClassroom,
  fetchSignedUrl,
  createSlots,
  deleteResource,
  updateBooking,
  createReward,
  fetchRecomendations,
  updateClass,
  updateClassDuration,
  fetchLink,
  createLink,
};
