import React, { useState, useEffect } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Flex,
  Spinner,
  Divider,
  Select,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Input,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import actions from './actions';
import { convertTZ, showErrorToast, showSuccessToast } from './utils';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const Upcoming = () => {
  const [items, setItems] = useState([]);
  const [resourceOptions, setResourceOptions] = useState({});
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [updating, setUpdating] = useState({});
  const [editBookings, setEditBookings] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchingVerification, setFetchingVerification] = useState(false);
  const { handleSubmit, errors, register, formState } = useForm();

  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      await fetchCohorts();
    };

    fetchData();
  }, []);

  const fetchCohorts = async () => {
    setLoading(true);
    try {
      const { items: _ = [] } = await actions.fetchCohorts();
      console.log(_);
      setItems(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const onTeacherChange = async (e, class_id, slot_id) => {
    updating[slot_id] = true;
    setUpdating({ ...updating });

    const teacher_id = e.target.value;
    try {
      await actions.updateSlot(class_id, slot_id, { teacher_id });
      await fetchCohorts();
      showSuccessToast(toast, '✅ Updated teacher');
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    updating[slot_id] = false;
    setUpdating({ ...updating });
  };

  const onJoinClass = async (class_id, slot_id) => {
    setFetchingVerification({ class_id, slot_id });
    try {
      await actions.fetchClassroom(class_id, slot_id, 'sendCode');
    } catch (err) {
      setFetchingVerification(false);
      showErrorToast(toast, err.message);
    }
  };

  const submitVerificationCode = async ({ code }) => {
    const { class_id, slot_id } = fetchingVerification;
    history.push(`/rooms/${class_id}/slots/${slot_id}?code=${code}&mode=verifyCode`);
  };

  const renderBooking = (booking = {}) => {
    const {
      parent,
      child,
      email,
      phone,
      child_id,
      parent_id,
      intercom_url,
      booking_id,
      status,
      stars = 0,
      browser,
      device,
      age,
    } = booking;

    return (
      <Stack
        key={booking_id}
        spacing={0}
        textAlign="left"
        fontSize="xs"
        p={2}
        border="1px"
        borderRadius="4px"
        borderColor="gray.300"
      >
        <Text>
          <strong>Parent:</strong> {parent} | <strong>Email:</strong> {email}
        </Text>
        <Text>
          <strong>Phone:</strong> {phone} |{' '}
          {intercom_url && (
            <a
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
              href={intercom_url}
            >
              Intercom
            </a>
          )}
        </Text>
        <Text>
          <strong>Child:</strong> {child} | <strong>Age:</strong> {age} | <strong>Stars:</strong>{' '}
          {stars}
          {status && (
            <>
              <Text as="span">{` | `}</Text>
              <Text
                as="span"
                fontSize="xs"
                fontWeight="bold"
                px={2}
                borderRadius="3px"
                bg={status === 'Joined' ? 'green.500' : 'red.500'}
                color="white"
              >
                {status}
              </Text>
            </>
          )}
        </Text>
        {(browser || device) && (
          <Text>
            <strong>Browser:</strong> {browser} | <strong>Device:</strong> {device}
          </Text>
        )}
        <Text>
          <strong>Child ID:</strong> {child_id}
        </Text>
        <Text>
          <strong>Parent ID:</strong> {parent_id}
        </Text>
        <Text>
          <strong>Booking ID:</strong> {booking_id}
        </Text>
      </Stack>
    );
  };

  const renderItem = (item = {}) => {
    const { title, id, grade, timestamp, cohorts = [], sections = [] } = item;

    return (
      <Box key={id} borderWidth={'1px'} borderRadius="4px" p={3} borderColor={'gray.200'}>
        <Stack spacing={2}>
          <Text fontWeight="bold">{title}</Text>
          <Box fontSize="xs" color="gray.600">
            <Text>Course ID: {id || 'N/A'}</Text>
            <Stack isInline justifyContent="center">
              <Text>Grade: {grade || 'N/A'}</Text>
            </Stack>
          </Box>
          <Box d="flex" justifyContent="center"></Box>

          <Box textAlign="left" fontSize="sm">
            <Stack spacing={2}>
              {sections.map(s => (
                <Box>
                  <Text>{s.title}</Text>
                  {s.lessons.map(l => (
                    <Box fontWeight="bold">
                      <Link to={`/classes/${l.id}`}>{l.title}</Link>
                    </Box>
                  ))}
                </Box>
              ))}
            </Stack>
          </Box>
          <Divider />
          <Box fontSize="sm">
            <Text mt={1} fontWeight="bold">
              Cohorts
            </Text>
            <Stack spacing={2}>
              {cohorts.map(c => (
                <Box p={2} borderWidth="1px" borderColor="gray.300" rounded="lg">
                  <Text>ID: {c.id}</Text>
                  <Text fontSize="sm">Date: {convertTZ(c.timestamp, { mode: 'date' })}</Text>
                </Box>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Box>
    );
  };

  // Render Methods
  const renderVerificationCodeModal = () => (
    <Modal isOpen={fetchingVerification} onClose={() => setFetchingVerification(false)}>
      <ModalOverlay />
      <ModalContent borderRadius="5px" p={5}>
        <ModalHeader>
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Text textAlign="center" fontWeight="bold" paddingTop="5px">
              Enter Verification Code
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Flex flexDir="column" justifyContent="center">
            <Text textAlign="center" paddingBottom="10px">
              Please enter the verification code sent to your phone to join this class
            </Text>
            <form onSubmit={handleSubmit(async data => await submitVerificationCode(data))}>
              <FormControl mt={4} isInvalid={errors.unit_id}>
                <Text fontWeight="bold">Verification Code</Text>
                <Input
                  name="code"
                  placeholder="Verification Code"
                  autoFocus
                  borderRadius="4px"
                  height="50px"
                  textAlign="center"
                  fontSize="lg"
                  ref={register({})}
                />
                <FormErrorMessage>{errors.unit_id && errors.unit_id.message}</FormErrorMessage>
              </FormControl>
            </form>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setFetchingVerification(false)}>Cancel</Button>
          <Button
            onClick={handleSubmit(async data => await submitVerificationCode(data))}
            color="white"
            bg="purple.500"
            fontWeight="bold"
            ml={3}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <Box maxW="lg" mx="auto" mt={5} p={5}>
      {fetchingVerification && renderVerificationCodeModal()}
      <Text fontWeight="black" fontSize="2xl">
        Courses
      </Text>
      <Divider />
      {loading && items.length === 0 && (
        <Box d="flex" my={4} justifyContent="center">
          <Spinner color="purple.500" />
        </Box>
      )}
      <Stack mt={2} spacing={2}>
        {items.map(renderItem)}
      </Stack>
      <Divider />
    </Box>
  );
};

export default Upcoming;
