import React, { useState, useEffect } from 'react';
import './App.css';
import {
  useToast,
  Text,
  Stack,
  Box,
  Select,
  Divider,
  Spinner,
  Button,
  Icon,
  useDisclosure,
  ModalFooter,
  ModalBody,
  ModalHeader,
  FormErrorMessage,
  Input,
  Modal,
  FormControl,
  ModalOverlay,
  ModalContent,
  Flex,
  useClipboard,
} from '@chakra-ui/core';
import { Link } from 'react-router-dom';
import actions from './actions';
import { showErrorToast, showSuccessToast } from './utils';

const User = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [fetchLinkResult, setFetchLinkResult] = useState(null);
  const [createLinkResult, setCreateLinkResult] = useState(null);

  const [query, setQuery] = useState(null);
  const [fetchLinkQuery, setFetchLinkQuery] = useState(null);
  const [createLinkQuery, setCreateLinkQuery] = useState(null);

  const [campaign_id, setCampaignId] = useState(null);
  const { onCopy, hasCopied } = useClipboard(createLinkResult && createLinkResult.url);

  const toast = useToast();

  const fetchItem = async e => {
    if (e) e.preventDefault();

    setLoading(true);
    try {
      const { result: _ = {} } = await actions.fetchRecomendations(query, campaign_id);
      console.log(_);
      setResult(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const fetchLink = async () => {
    setLoading(true);
    try {
      const { result: _ = {} } = await actions.fetchLink(fetchLinkQuery);
      console.log(_);
      setFetchLinkResult(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const createLink = async () => {
    setLoading(true);
    try {
      const { result: _ = {} } = await actions.createLink(createLinkQuery);
      console.log(_);
      setCreateLinkResult(_);
    } catch (err) {
      showErrorToast(toast, err.message);
    }
    setLoading(false);
  };

  const renderResults = () => {
    if (result) {
      const { history = [], recommendations = [], parent } = result;

      if (history.length > 0) {
      }
    }
  };

  const renderHistory = (items = []) => {
    return (
      <Stack spacing={2}>
        {items.map(item => {
          const { title, subject, teacher, date } = item;
          return (
            <Box
              py={2}
              d="flex"
              flexDir="column"
              alignItems="center"
              key={`${title}#${subject}`}
              rounded="lg"
              border="1px"
            >
              <Text fontSize="md">{title}</Text>
              <Flex fontSize="sm">
                <Text>{subject}</Text>
                <Text mx={2}>|</Text>
                <Text fontStyle="italic">{teacher}</Text>
              </Flex>
              <Text fontSize="sm">{date}</Text>
            </Box>
          );
        })}
      </Stack>
    );
  };

  const renderRecommendations = (items = []) => {
    return (
      <Stack spacing={2}>
        {items.map(item => {
          const { id, metadata = [] } = item;
          return (
            <Box
              py={2}
              d="flex"
              flexDir="column"
              alignItems="center"
              key={id}
              rounded="lg"
              border="1px"
            >
              <Stack fontSize="sm">
                <Text>ID: {id}</Text>
                {metadata.map(m => (
                  <Text key={m}>{m}</Text>
                ))}
              </Stack>
            </Box>
          );
        })}
      </Stack>
    );
  };

  return (
    <Box maxW="xl" mx="auto" mt={5} p={5}>
      <Stack spacing={4}>
        <Box>
          <Text fontWeight="bold">User Lookup</Text>
          <Input placeholder="User ID or Email or Phone" onChange={e => setQuery(e.target.value)} />

          <Button
            isLoading={loading}
            bg="purple.500"
            color="white"
            fontWeight="bold"
            onClick={fetchItem}
            w="100%"
            mt={2}
          >
            Search
          </Button>

          <Divider />
          {result && (
            <Box mt={2}>
              <Stack>{renderRecommendations(result.items)}</Stack>
            </Box>
          )}
        </Box>
        <Divider />
        <Box>
          <Text fontWeight="bold">Link Lookup</Text>
          <Input placeholder="Link ID" onChange={e => setFetchLinkQuery(e.target.value)} />

          <Button
            isLoading={loading}
            bg="purple.500"
            color="white"
            fontWeight="bold"
            onClick={fetchLink}
            w="100%"
            mt={2}
          >
            Search
          </Button>

          <Divider />
          {fetchLinkResult && (
            <Box border="1px" rounded="lg" p={3} textAlign="left" mt={2}>
              <Text>
                <b>URL:</b>{' '}
                <a style={{ textDecoration: 'underline' }} href={fetchLinkResult.url}>
                  {fetchLinkResult.url}
                </a>
              </Text>
              <Text>
                <b>Clicks:</b> {fetchLinkResult.clicks}
              </Text>
              <Text>
                <b>Created At:</b> {new Date(fetchLinkResult.timestamp).toISOString()}
              </Text>
            </Box>
          )}
        </Box>
        <Divider />
        <Box>
          <Text fontWeight="bold">Create Shortlink</Text>
          <Input placeholder="URL" onChange={e => setCreateLinkQuery(e.target.value)} />

          <Button
            isLoading={loading}
            bg="purple.500"
            color="white"
            fontWeight="bold"
            onClick={createLink}
            w="100%"
            mt={2}
          >
            Create Link
          </Button>

          <Divider />
          {createLinkResult && (
            <Box border="1px" rounded="lg" p={3} textAlign="left" mt={2}>
              <Text>
                <a
                  style={{ textDecoration: 'underline' }}
                  target="_blank"
                  rel="noreferrer"
                  href={createLinkResult.url}
                >
                  {createLinkResult.url}
                </a>
              </Text>
              <Button bg="gray.300" fontWeight="bold" mt={2} onClick={onCopy} w="100%">
                {hasCopied ? `✅ Copied` : 'Copy'}
              </Button>
            </Box>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default User;
