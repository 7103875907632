import React from 'react';
import { Box, Heading, Flex, Button, Text } from '@chakra-ui/core';
import { useLocation, Link } from 'react-router-dom';

const Header = ({ handleLogout, authState, onRouteChange, profile, ...props }) => {
  const location = useLocation();

  return (
    <>
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1.5rem"
        bg="purple.500"
        backgroundImage="linear-gradient(135deg,#6c33da,#a431ff)"
        color="white"
        {...props}
      >
        <Flex align="center" mr={5} mt={3}>
          <Heading as="h1" size="lg">
            ZipSchool Admin 🚀
          </Heading>
        </Flex>

        <Box>
          <Link to="/classes">
            <Button
              mt={3}
              mr={3}
              p={[2, 4]}
              bg={location.pathname.startsWith('/classes') ? 'white' : 'transparent'}
              border={location.pathname.startsWith('/classes') ? 'none' : '1px'}
              borderColor={location.pathname.startsWith('/classes') ? 'none' : 'white'}
              color={location.pathname.startsWith('/classes') ? 'black' : 'white'}
              fontWeight={location.pathname.startsWith('/classes') ? 'bold' : 'normal'}
              rounded="full"
            >
              <Text fontSize={['xs', 'md']}>Lessons</Text>
            </Button>
          </Link>
          <Link to="/courses">
            <Button
              mt={3}
              mr={3}
              p={[2, 4]}
              bg={location.pathname.startsWith('/courses') ? 'white' : 'transparent'}
              border={location.pathname.startsWith('/courses') ? 'none' : '1px'}
              borderColor={location.pathname.startsWith('/courses') ? 'none' : 'white'}
              color={location.pathname.startsWith('/courses') ? 'black' : 'white'}
              fontWeight={location.pathname.startsWith('/courses') ? 'bold' : 'normal'}
              rounded="full"
            >
              <Text fontSize={['xs', 'md']}>Courses</Text>
            </Button>
          </Link>
          <Link to="/utils">
            <Button
              mt={3}
              mr={3}
              p={[2, 4]}
              bg={location.pathname.startsWith('/utils') ? 'white' : 'transparent'}
              border={location.pathname.startsWith('/utils') ? 'none' : '1px'}
              borderColor={location.pathname.startsWith('/utils') ? 'none' : 'white'}
              color={location.pathname.startsWith('/utils') ? 'black' : 'white'}
              fontWeight={location.pathname.startsWith('/utils') ? 'bold' : 'normal'}
              rounded="full"
            >
              <Text fontSize={['xs', 'md']}>Utils</Text>
            </Button>
          </Link>
        </Box>
      </Flex>
    </>
  );
};

export default Header;
