/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Text,
  Stack,
  useDisclosure,
  ModalBody,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
} from '@chakra-ui/core';
import { LocalDataTrack, createLocalTracks, connect } from 'twilio-video';
import actions from '../actions';
import Participant from './Participant';
import { useHistory } from 'react-router-dom';

const VideoWall = ({
  token,
  roomId,
  onEndRoom,
  teacherName,
  loading,
  students,
  startAt = 0,
  duration = 0,
  slotId,
  questions = [],
  triggers = {},
  setRoomRef,
  drawingMode,
  currentSlide,
  setCurrentSlide,
}) => {
  // State
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [participantStarCountMap, setParticipantStarCountMap] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [participantsVideosHidden, setParticipantsVideosHidden] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [countdown, setCountdown] = useState('00:00');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [teacherAudioLevel, setTeacherAudioLevel] = useState(null);
  const history = useHistory();

  const [participantMap, setParticipantMap] = useState(() => {
    const retval = { teacher: { name: teacherName } };
    students.forEach(s => (retval[s.id] = { name: s.name, color: s.color, volume: 1 }));
    return retval;
  });

  // Actions
  const dominantSpeakerChanged = participant => {
    console.log('The new dominant speaker in the Room is:', participant);
  };

  const endLocalRoom = () => {
    if (room && room.localParticipant.state === 'connected') {
      room.localParticipant.tracks.forEach(trackPublication => {
        if (trackPublication.track.kind !== 'data') trackPublication.track.stop();
        trackPublication.unpublish();
      });

      room.disconnect();
      window.removeEventListener('beforeunload', () => room.disconnect());
      window.removeEventListener('pagehide', () => room.disconnect());
    }
  };

  const handleOnStarEvent = participantId => {
    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    if (localDataTrackPublication) {
      try {
        localDataTrackPublication.track.send(JSON.stringify({ action: 'star', participantId }));
        const participantStarValue = participantStarCountMap[participantId];

        // We check explicitly for null and undefined because 0 is considered falsy!
        if (participantStarValue !== null || participantStarValue !== undefined) {
          setParticipantStarCountMap(prevState => {
            return { ...prevState, [participantId]: participantStarValue + 1 };
          });
        }
        actions.createStarActionAsync(slotId, participantId);
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const onVolumeChange = (participantId, value) => {
    if (!participantMap[participantId]) participantMap[participantId] = {};

    Object.keys(participantMap).forEach(p => {
      if (participantMap[p].volume === 8 && value === 8) {
        participantMap[p].volume =
          'prevVolume' in participantMap[p] ? participantMap[p].prevVolume : 1;
      }
    });
    participantMap[participantId].prevVolume = participantMap[participantId].volume;
    participantMap[participantId].volume = value;

    setParticipantMap(participantMap);
    setParticipants([...participants]);

    const [localDataTrackPublication] = [...room.localParticipant.dataTracks.values()];
    if (localDataTrackPublication)
      localDataTrackPublication.track.send(JSON.stringify({ participants: participantMap }));
  };

  const toggleMute = () => {
    if (!room || !room.localParticipant || !room.localParticipant.audioTracks) return;
    const iterator = room.localParticipant.audioTracks.values();
    const publication = iterator.next().value;
    if (publication) {
      if (publication.isTrackEnabled) {
        publication.track.disable();
      } else {
        publication.track.enable();
      }
      setIsMuted(publication.isTrackEnabled === false);
    }
  };

  // UseEffects
  useEffect(() => {
    const interval = setInterval(() => {
      const durationMs = duration * 60 * 1000;
      const hasHours = duration / 60 >= 1.0;
      const endAt = startAt + durationMs;
      if (Date.now() < startAt) {
        const hours = Math.floor(duration / 60);
        setCountdown(hasHours ? `${hours}:${duration}:00` : `${duration}:00`);
      } else if (Date.now() > startAt && Date.now() < endAt) {
        const elapsed = Date.now() - startAt;
        const remainingMs = durationMs - elapsed;

        const hours = remainingMs / 1000 / 60 / 60;
        const rhours = Math.floor(hours).toString().padStart(2, '0');
        const minutes = (hours - rhours) * 60;
        const rminutes = Math.floor(minutes).toString().padStart(2, '0');
        const seconds = (minutes - rminutes) * 60;
        const reconds = Math.floor(seconds).toString().padStart(2, '0');

        setCountdown(hasHours ? `${rhours}:${rminutes}:${reconds}` : `${rminutes}:${reconds}`);
      } else {
        setCountdown(hasHours ? '00:00:00' : '00:00');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const participantConnected = participant => {
      setParticipants(prevParticipants => {
        for (const student of students) {
          if (student.id === participant.identity && !('displayIndex' in student)) {
            const displayIndices = students
              .filter(s => 'displayIndex' in s)
              .map(s => s.displayIndex);
            const maxDisplayIndex = displayIndices.length > 0 ? Math.max(displayIndices) + 1 : 0;
            student.displayIndex = maxDisplayIndex;
          }
        }
        return [...prevParticipants, participant];
      });
      setParticipantStarCountMap(prevParticipants => {
        return { ...prevParticipants, [participant.identity]: 0 };
      });
    };

    const participantDisconnected = participant => {
      setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
    };

    createLocalTracks({
      audio: false,
      video: false,
    })
      .then(localTracks => {
        return connect(token, {
          name: roomId,
          tracks: [new LocalDataTrack()],
        });
      })
      .then(twilioRoom => {
        setRoom(twilioRoom);

        if (setRoomRef) {
          // We set this so we can user room data in Dashboard component
          setRoomRef(twilioRoom);
        }

        twilioRoom.on('participantConnected', participantConnected);
        twilioRoom.on('participantDisconnected', participantDisconnected);
        twilioRoom.on('dominantSpeakerChanged', dominantSpeakerChanged);
        twilioRoom.participants.forEach(participantConnected);
        // Listen to the "beforeunload" event on window to leave the Room
        // when the tab/browser is being closed.
        window.addEventListener('beforeunload', () => twilioRoom.disconnect());

        // iOS Safari does not emit the "beforeunload" event on window.
        // Use "pagehide" instead.
        window.addEventListener('pagehide', () => twilioRoom.disconnect());
      })
      .catch(err => {
        console.error('TWILIO ERR: ', err);
      });

    return () => {
      endLocalRoom();
    };
  }, []);

  const onDataTrackMessage = (eventString = '') => {
    let eventData;
    try {
      eventData = JSON.parse(eventString);
    } catch (err) {
      console.warn(err);
      return;
    }

    const { action, slide, data = {} } = eventData;
    if (action === 'answer') {
      setCurrentGame(prevGame => {
        if (prevGame) {
          const studentAnswers = prevGame.studentAnswers || {};
          studentAnswers[data.student_id] = `✅ Responded`;
          console.log('Student Answers: ', studentAnswers);
          return { ...prevGame, studentAnswers };
        }

        return prevGame;
      });

      setCurrentQuestion(prevQuestion => {
        if (prevQuestion) {
          const studentAnswers = prevQuestion.studentAnswers || {};
          studentAnswers[data.student_id] = data.answer_id;

          return { ...prevQuestion, studentAnswers };
        }

        return prevQuestion;
      });
    } else if (action === 'completed') {
      history.push(`/upcoming`);
    } else if (action === 'slide') {
      setCurrentSlide(slide);
    }
  };

  const renderRemoteParticipants = () => {
    students.sort((a, b) => {
      const aDisplayIndex = 'displayIndex' in a ? a.displayIndex : Number.MAX_SAFE_INTEGER;
      const bDisplayIndex = 'displayIndex' in b ? b.displayIndex : Number.MAX_SAFE_INTEGER;

      return aDisplayIndex - bDisplayIndex;
    });

    const remoteParticipants = students.map(s => {
      let currentParticipantVolume = 1;
      if (participantMap[s.id] && 'volume' in participantMap[s.id]) {
        currentParticipantVolume = participantMap[s.id].volume;
      }

      const participant = participants.filter(p => p.identity === s.id).pop();

      var studentResponse;
      if (currentGame || currentQuestion) {
        const { studentAnswers = {} } = currentGame || currentQuestion;
        studentResponse = studentAnswers[s.id];
      }

      return (
        <Box key={s.id} w="33.33%" pr={'0.25rem'} pb={'0.25rem'}>
          <Participant
            participant={participant}
            student={s}
            volume={currentParticipantVolume}
            onVolumeChange={onVolumeChange}
            onStarEvent={handleOnStarEvent}
            setParticipantsVideosHidden={setParticipantsVideosHidden}
            didReceiveDataTrackMessage={onDataTrackMessage}
            videoHidden={participantsVideosHidden.includes(s.id)}
            participantStarCount={participantStarCountMap[s.id]}
            studentResponse={studentResponse}
          />
        </Box>
      );
    });

    return remoteParticipants;
  };

  const renderQuestionModal = () => (
    <Box
      d="flex"
      alignItems="center"
      style={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '30%',
        height: '100%',
        zIndex: 1400,
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
      p={5}
      marginTop="auto"
    >
      <Box
        bg="white"
        borderRadius="4px"
        borderWidth="2px"
        borderColor="black"
        boxShadow="2px 2px 3px #444444"
        w="100%"
      >
        <ModalBody mt={10}>
          <Text fontSize="md" color="gray.500" textAlign="center">
            🔴 LIVE ON STUDENT SCREEN 🔴
          </Text>
          <Text mt={8} fontWeight="bold" fontSize="2xl" textAlign="center">
            {currentQuestion.title}
          </Text>
          <Stack mt={4}>
            {currentQuestion.answers.map(a => (
              <Box
                key={a.title}
                p={2}
                borderWidth={a.isCorrect ? '3px' : '1px'}
                borderColor={a.isCorrect ? 'purple.500' : 'black'}
                rounded="full"
              >
                <Text fontSize="xl" fontWeight={a.isCorrect ? 'bold' : 'normal'} textAlign="center">
                  {a.title}
                </Text>
              </Box>
            ))}
          </Stack>
          <Divider />
          <Text fontWeight="medium" fontSize="lg" mt={3}>
            Student Answers
          </Text>
          <Stack mt={3}>{renderStudentAnswers(currentQuestion.studentAnswers)}</Stack>
          <Divider />
        </ModalBody>
      </Box>
    </Box>
  );

  const renderStudentAnswers = (answers = {}) => {
    const studentsCopy = students.slice();
    studentsCopy.sort((a, b) => {
      return a.name - b.name;
    });

    return studentsCopy.map(s => (
      <Box d="flex" key={s.id} w="100%">
        <Text fontWeight="bold">{`${s.name} -`}</Text>
        <Text ml={1} color={answers[s.id] ? 'black' : 'gray.500'}>
          {answers[s.id] || 'waiting on answer...'}
        </Text>
      </Box>
    ));
  };

  const renderTeacher = () => {
    if (!room) return;

    const teacher = participants.find(p => p.identity === 'teacher');
    if (teacher) {
      return <Participant participant={teacher} didReceiveDataTrackMessage={onDataTrackMessage} />;
    }
  };

  const renderTeacherAudioIndicator = () => {
    let renderedAudioLevel = '';
    if (teacherAudioLevel >= 8) renderedAudioLevel = `)))`;
    else if (teacherAudioLevel >= 6) renderedAudioLevel = '))';
    else if (teacherAudioLevel >= 3) renderedAudioLevel = ')';
    // else if (teacherAudioLevel === 0) renderedAudioLevel = '⛔️';

    return (
      <Box bg="purple.200" borderRadius="4px" p={1} w="45px">
        <Text color="black">{`🔈${renderedAudioLevel}`}</Text>
      </Box>
    );
  };

  return (
    <Box
      d={drawingMode ? 'none' : 'block'}
      bg="black"
      p={0}
      borderWidth="3px"
      borderColor="purple.400"
      w="70%"
    >
      {room && (
        <>
          {/* Teacher Controls */}
          <Box
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            overflow="hidden"
            borderBottomWidth="3px"
            borderBottomColor="purple.400"
            p={3}
          >
            {/* Timer And Questions */}
            <Box maxH="100%" w="50%" textAlign="center">
              <Box d="flex" w="100%" justifyContent="center" alignItems="center" flexDir="column">
                <Text color="white" fontWeight="medium">
                  Time Remaining
                </Text>
                <Text color="white" fontSize="5xl" fontWeight="bold">
                  {countdown}
                </Text>
              </Box>
            </Box>
            <Box border="1px solid yellow" bg="purple.500" w="25%" alignItems="center">
              {renderTeacher()}
            </Box>
          </Box>

          {/* Students */}
          <Box d="flex" flexWrap="wrap" bg="black" w="100%" pl="0.25rem" pt="0.25rem">
            {renderRemoteParticipants()}
          </Box>
        </>
      )}

      {currentQuestion && currentQuestion !== null && renderQuestionModal()}
    </Box>
  );
};

export default VideoWall;
